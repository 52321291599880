<template>
  <div id="printArea">
    <div class="row">
      <div class="col-md-10">
        <h4 class="text-uppercase">Notice Details</h4>
      </div>
      <div class="col-md-2 text-right d-print-none">
        <a href="javascript:void(0)" class="print-btn" @click="print()"
          ><i class="fa fa-print" aria-hidden="true"></i> Print</a
        >
      </div>
    </div>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="12" class="text-right d-print-none">
              <!-- <CButton @click="print()" color="info" shape="pill"
                >Print</CButton
              > -->
            </CCol>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless appl-view-tbl">
                  <tbody>
                    <tr>
                      <td>Title:</td>
                      <td>{{ notice.title }}</td>
                      <td>Description:</td>
                      <td colspan="3">{{ notice.description }}</td>
                    </tr>
                    <tr>
                      <td>Expired Date:</td>
                      <td>{{ _dateFormat(notice.expired_date) }}</td>
                      <td>Status:</td>
                      <td><span class="badge" :class="(notice.status === 'PENDING')?'badge-primary':'badge-success'">{{ notice.status }}</span></td>
                      <td>Channels:</td>
                      <td>
                        <span
                          class="badge badge-primary mr-2"
                          v-for="(item, key) in notice.channel"
                          :key="key"
                          > {{ item }} </span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>Audiences:</td>
                      <td>
                        <ul class="audience-list" v-if="notice.audience && notice.audience.area.length">
                          <li><strong>Areas:</strong></li>
                          <li v-for="(item, index) in notice.audience.area" :key="index">{{ item }}</li>
                        </ul>

                        <ul class="audience-list" v-if="notice.audience && notice.audience.ward_number.length">
                          <li><strong>Wards:</strong></li>
                          <li v-for="(item, index) in notice.audience.ward_number" :key="index">{{ item }}</li>
                        </ul>
                      </td>
                      <td>Created At</td>
                      <td>{{ _dateFormat(notice.created_at) }}</td>
                      <td v-if="notice.status === 'PUBLISHED'">Published At</td>
                      <td v-if="notice.status === 'PUBLISHED'">{{ _dateTimeFormat(notice.published_at) }}</td>
                      <td class="d-print-none" colspan="2" v-if="checkUsrPermissions(['Notice_all', 'Notice_publish']) && notice.status === 'PENDING'">
                        <button
                          type="button"
                          class="btn btn-primary btn-lg px-5"
                          @click="publish()"
                        >
                          Publish Now
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NoticeViewComp",
  data: () => {
    return {
      printPage: false,
    };
  },
  methods: {
    publish() {
      this.$confirm({
        message: `Are you sure, want to publish the notice?`,
        button: {
          no: "No",
          yes: "Yes, Publish Now!",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("Settings/loading", true);
            this.$store
              .dispatch("Notice/publish", this.$route.params.id)
              .then(
                () => {
                  this.$store.dispatch("Settings/loading", false);
                  this.$toastr.s(
                    "Notice successfuly published.",
                    "Success!"
                  );
                },
                (error) => {
                  this.$toastr.e(error.response.data.message, "Failed!");
                  this.$store.dispatch("Settings/loading", false);
                }
              );
          }
        },
      });
    },
    print() {
      this.printPage = true;
      setTimeout(() => {
        this.printSetup();
      }, 50);
    },

    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>Notice Details</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener("load", () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
          this.printPage = false;
        }, 200);
      });
    },
  },
  computed: { ...mapGetters("Notice", ["notice"]) },
  mounted() {
    this.$store.dispatch("Settings/loading", true);
    this.$store.dispatch(
      "Notice/find",
      this.$route.params.id
    ).then(() => {
      this.$store.dispatch("Settings/loading", false);
    }, () => {
      this.$store.dispatch("Settings/loading", false);
    });
  },
};
</script>

<style scoped>
.custom-close-button >>> button {
  color: aliceblue;
}
.hide-footer >>> footer {
  display: none;
}
.card {
  border: none;
  padding: 30px 20px;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 0;
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.custom-width {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.avatar-container {
  width: 100%;
  padding: 4px;
  text-align: center;
  img {
    border: 1px solid #ccc;
    max-width: 100%;
  }
}
.appl-view-tbl {
  margin-bottom: 40px;
  tr {
    td {
      font-size: 14px;
      font-weight: 600;
      &:nth-child(odd) {
        color: #666;
        width: 14%;
      }
      &:nth-child(even) {
        color: #333;
        width: 19%;
      }
    }
  }
}

.logs-title {
  background: #f8f9fa;
  color: #000;
  padding: 15px;
}

.log-list {
  border: 1px solid #dadada;
  background-color: #eff0f5;
  border-radius: 4px;
  padding: 15px;
  position: relative;
  font-size: 13px;
  color: #212121;
  word-break: break-word;
  div {
    line-height: 2;
  }
  span {
    color: #9e9e9e;
  }
}

.print-btn {
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  i {
    font-size: 28px;
  }
}

.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 35%;
        max-width: 100px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}

div {
  .row {
    .card {
      p {
        color: #000;
      }
      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        // td:nth-child(1) {
        //   color: #5e76e7;
        // }
        // td:nth-child(2) {
        //   font-weight: 500 !important;
        // }
      }
    }
  }
}
.audience-list {
  list-style-type: none;
  padding: 0;
  width: 50%;
  float: left;
  li {
    &:first-of-type {
      font-weight: 600;
      text-decoration: underline;
      color: #321fdb;
    }
  }
}
</style>
